import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/Software/iOS/NavButtons';
import ForumBox from 'components/Software/iOS/IP_Cam_Viewer/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "iOS Software for your INSTAR IP Camera",
  "path": "/Software/iOS/IP_Cam_Viewer/",
  "dateChanged": "2017-12-05",
  "author": "Mike Polinowski",
  "excerpt": "The Android app IP CamViewer for your Android tablet or smartphone. Control your camera when you don´t have access to your computer.",
  "image": "./P_SearchThumb_IP_Cam_Viewer.png",
  "social": "/images/Search/P_SearchThumb_IP_Cam_Viewer.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Android_IPCamViewer_white.webp",
  "chapter": "Software"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const EuiSpacer = makeShortcode("EuiSpacer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='iOS Software for your INSTAR IP Camera' dateChanged='2017-12-08' author='Mike Polinowski' tag='INSTAR IP Camera' description='The iOS app IP CamViewer for your iPad or iPhone. Control your camera when you don´t have access to your computer.' image='/images/Search/P_SearchThumb_IP_Cam_Viewer.png' twitter='/images/Search/P_SearchThumb_IP_Cam_Viewer.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/iOS/IP_Cam_Viewer/' locationFR='/fr/Software/iOS/IP_Cam_Viewer/' crumbLabel="IP Cam Viewer" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "ios",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ios",
        "aria-label": "ios permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`iOS`}</h2>
    <h3 {...{
      "id": "ip-cam-viewer",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#ip-cam-viewer",
        "aria-label": "ip cam viewer permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IP Cam Viewer`}</h3>
    <p>{`To access your IP camera with the Android App IP Cam Viewer we recommend that you first download the newest version from the app market. Use the search word `}<a href="https://play.google.com/store/apps/details?id=com.rcreations.WebCamViewerPaid&hl=en" target="_blank" rel="noopener noreferrer">{`IP Cam Viewer`}</a>{` to find our app inside the Play Store.`}</p>
    <h3 {...{
      "id": "add-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#add-cameras",
        "aria-label": "add cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add Cameras`}</h3>
    <p>{`After you have installed the newest version please start the App and click the + button in the lower left to enter the Add Camera Wizard.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ed69fc7f55ca7fa6484c299315396c9d/21b4d/IP-Cam-Viewer_Android_(1).png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.60869565217391%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsTAAALEwEAmpwYAAABMElEQVQoz53Ky07CQBiG4b8mghzaTqdAh047pVB6spRDKzG6ceUF4KJAiRgX7jExMfE2jN6uAVmYtMPCyZPJn3wvLFerZdnL83y73a7X6+KUZdlms9ntXkEQzkqBcAYg8FbhsEKt3uBoHjROBNAU5X8DWVFLSQiLMpYQ5gWyogJuaUWK2jEtezD0dcNS1E5pg1satAktRSjTzR6hjBe0CQVCrVKazjSdEcp4AaEWGJaz13OORwHlMCwHTNs1bdfoDc2+9xfre2zgn9L3YDSdR5N5nFwHcRLESXiU+tFsGIx53HDsXk7h7f3j8fnl8+t7kt4EcRqOr34FcepFs6NRshftfz9OnXBye3ef5U+AFDxLk8XDQut2zyvVSvWiqNZsiJKMFIxxqy6hqoiQYau6+QPO0lxsSu6rNQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ed69fc7f55ca7fa6484c299315396c9d/e4706/IP-Cam-Viewer_Android_(1).avif 230w", "/en/static/ed69fc7f55ca7fa6484c299315396c9d/d1af7/IP-Cam-Viewer_Android_(1).avif 460w", "/en/static/ed69fc7f55ca7fa6484c299315396c9d/7f308/IP-Cam-Viewer_Android_(1).avif 920w", "/en/static/ed69fc7f55ca7fa6484c299315396c9d/d7c22/IP-Cam-Viewer_Android_(1).avif 1280w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ed69fc7f55ca7fa6484c299315396c9d/a0b58/IP-Cam-Viewer_Android_(1).webp 230w", "/en/static/ed69fc7f55ca7fa6484c299315396c9d/bc10c/IP-Cam-Viewer_Android_(1).webp 460w", "/en/static/ed69fc7f55ca7fa6484c299315396c9d/966d8/IP-Cam-Viewer_Android_(1).webp 920w", "/en/static/ed69fc7f55ca7fa6484c299315396c9d/af3f0/IP-Cam-Viewer_Android_(1).webp 1280w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ed69fc7f55ca7fa6484c299315396c9d/81c8e/IP-Cam-Viewer_Android_(1).png 230w", "/en/static/ed69fc7f55ca7fa6484c299315396c9d/08a84/IP-Cam-Viewer_Android_(1).png 460w", "/en/static/ed69fc7f55ca7fa6484c299315396c9d/c0255/IP-Cam-Viewer_Android_(1).png 920w", "/en/static/ed69fc7f55ca7fa6484c299315396c9d/21b4d/IP-Cam-Viewer_Android_(1).png 1280w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ed69fc7f55ca7fa6484c299315396c9d/c0255/IP-Cam-Viewer_Android_(1).png",
              "alt": "IP Cam Viewer for your INSTAR IP Camera",
              "title": "IP Cam Viewer for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Fig1.`}</strong>{` Click on the + button to add a new camera.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/df8623521b69cde2c9034900e384f963/21b4d/IP-Cam-Viewer_Android_(2).png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.60869565217391%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsTAAALEwEAmpwYAAABSUlEQVQoz3XR2UrEMBQG4EyzLyfbSTqi4M20I6KDeq1equ//RlLD1NTl5yMU+ofTpOSwzXTOPM/H43GapsNfmabpdDqR3TbkO7vmvyxvKWMrxjl470NwAEJKLkSPMb4pM044FyspVSkVsQDAMAz9GMaYELIvcy6IkKrhQmptSikpJUTUWndHIJRSqfRabohUuhFKa2NrrTlnxtiPi2CMKW3WckOUsSvjoJQyjmOMkWxDKdXW9WVlLDEWVs75nHMIIcbYPrufbB30ZWOBWOcXsKwOAiKmlKSU299GKGWuNTvEQXAQLATnI/gYE8aMFryx0PfcV+EHgjhmHLHsUy4pl5gWCWvKtT03IZUQcSMheXh8mm9un19ecbxY9pzFXMJZ3KghlYur68N8JFKpu9P928c71rIbhoHS37gS2hgH4H0QxlFt7f7S5voJCfclYUqAQw0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/df8623521b69cde2c9034900e384f963/e4706/IP-Cam-Viewer_Android_(2).avif 230w", "/en/static/df8623521b69cde2c9034900e384f963/d1af7/IP-Cam-Viewer_Android_(2).avif 460w", "/en/static/df8623521b69cde2c9034900e384f963/7f308/IP-Cam-Viewer_Android_(2).avif 920w", "/en/static/df8623521b69cde2c9034900e384f963/d7c22/IP-Cam-Viewer_Android_(2).avif 1280w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/df8623521b69cde2c9034900e384f963/a0b58/IP-Cam-Viewer_Android_(2).webp 230w", "/en/static/df8623521b69cde2c9034900e384f963/bc10c/IP-Cam-Viewer_Android_(2).webp 460w", "/en/static/df8623521b69cde2c9034900e384f963/966d8/IP-Cam-Viewer_Android_(2).webp 920w", "/en/static/df8623521b69cde2c9034900e384f963/af3f0/IP-Cam-Viewer_Android_(2).webp 1280w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/df8623521b69cde2c9034900e384f963/81c8e/IP-Cam-Viewer_Android_(2).png 230w", "/en/static/df8623521b69cde2c9034900e384f963/08a84/IP-Cam-Viewer_Android_(2).png 460w", "/en/static/df8623521b69cde2c9034900e384f963/c0255/IP-Cam-Viewer_Android_(2).png 920w", "/en/static/df8623521b69cde2c9034900e384f963/21b4d/IP-Cam-Viewer_Android_(2).png 1280w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/df8623521b69cde2c9034900e384f963/c0255/IP-Cam-Viewer_Android_(2).png",
              "alt": "IP Cam Viewer for your INSTAR IP Camera",
              "title": "IP Cam Viewer for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Fig2.`}</strong>{` Choose to add a new IP Camera.`}</p>
    <h3 {...{
      "id": "camera-setup",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#camera-setup",
        "aria-label": "camera setup permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Camera Setup`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3e1f48c824c535e893b0d8426970700f/21b4d/IP-Cam-Viewer_Android_(3).png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.60869565217391%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB90lEQVQoz43MzW/ScBzH8d9JYK48tDie262lD4y2junFw0KcBBjDh39lUYMJNze9mLg+CISYSDRxHtioTsMFQtuFh4uBqXOAceFfMYAyxzT6zivffE8fUKlUqtWqruvaf6eqar1eLxQKYGfnTbFYLO7uKorybtj+pPenffjNMEVR0uk0KJWUcrlcq9UajUZNVX+t65qmq6qqjzs4Q9O0VquVy2XB5uaWJMtbjx6nUg8ymawgiIIoCaIkSlI+/1yUMoIoT9kW5Wwuv3E3BZrNZrvdVpS3e3ulwWDQ7XZ7vV6/3//0+Us2I39tN066hyfdzk+9zrejj8ed1vfjw4P9l+DpthCJxpM3bydv3YnGErF4IhZfjyeSN6JxnPBHVsOJtdhYcn1t9Xr4/r2N169ePJME+clDsOCnLfCcFZkbXyviMNvssxYYsiKIww1ZL81akIkZyObwoAS9iBEUSjBgkbvGL63woRU+FA5evhrklpggTwVYDCddXsyD4t6z3N55h9vn9KBONwqoAEcyrH+EDHBkgMMIBiNo3wLpwfzneTG/b570jgAUp1GcGSJolBg/DIrTo4l/ADjN4TQ7ulPYU9SfASoYmiD/gpq2THFXKHYZGE0zLM9HolGHy3XBYDAYjeeZoIuQ2WKDYRixm8w2oxm2uDHI7vwBle4clRB08S0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3e1f48c824c535e893b0d8426970700f/e4706/IP-Cam-Viewer_Android_(3).avif 230w", "/en/static/3e1f48c824c535e893b0d8426970700f/d1af7/IP-Cam-Viewer_Android_(3).avif 460w", "/en/static/3e1f48c824c535e893b0d8426970700f/7f308/IP-Cam-Viewer_Android_(3).avif 920w", "/en/static/3e1f48c824c535e893b0d8426970700f/d7c22/IP-Cam-Viewer_Android_(3).avif 1280w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3e1f48c824c535e893b0d8426970700f/a0b58/IP-Cam-Viewer_Android_(3).webp 230w", "/en/static/3e1f48c824c535e893b0d8426970700f/bc10c/IP-Cam-Viewer_Android_(3).webp 460w", "/en/static/3e1f48c824c535e893b0d8426970700f/966d8/IP-Cam-Viewer_Android_(3).webp 920w", "/en/static/3e1f48c824c535e893b0d8426970700f/af3f0/IP-Cam-Viewer_Android_(3).webp 1280w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3e1f48c824c535e893b0d8426970700f/81c8e/IP-Cam-Viewer_Android_(3).png 230w", "/en/static/3e1f48c824c535e893b0d8426970700f/08a84/IP-Cam-Viewer_Android_(3).png 460w", "/en/static/3e1f48c824c535e893b0d8426970700f/c0255/IP-Cam-Viewer_Android_(3).png 920w", "/en/static/3e1f48c824c535e893b0d8426970700f/21b4d/IP-Cam-Viewer_Android_(3).png 1280w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3e1f48c824c535e893b0d8426970700f/c0255/IP-Cam-Viewer_Android_(3).png",
              "alt": "IP Cam Viewer for your INSTAR IP Camera",
              "title": "IP Cam Viewer for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Fig3.`}</strong>{` `}<em parentName="p">{`HD Cameras`}</em>{`: Choose the correct INSTAR camera profile, type in your camera´s IP or `}<a parentName="p" {...{
        "href": "/en/Internet_Access/The_DDNS_Service/"
      }}>{`DDNS Address`}</a>{` and web port and add your personal camera login. Your HD camera offers you three video channels with different resolutions - 11, 12 and 13. We recommend using the channel 12 when accessing your camera via the internet (you can lower it to channel 13 if you have troubles connecting to your camera).`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1c938d9392f59a9182a7cb0cf90e77ce/21b4d/IP-Cam-Viewer_Android_(4).png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.60869565217391%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsTAAALEwEAmpwYAAACDUlEQVQoz2PYu2/fwYMHD5AC9u/ff+TIkfnz5zMsW758zZo1q1evXrNm7dq1a9dBwXoksGH9BhSwfv2G1atXV1RWMqxbv37btm179+47d+7c2bNnz56DgPPnz50HM85ewADnzp2/du3aokWLGBqbmidPntLR2RkdGxcXnxgblxCXACJjYuMTk1MSklJ8/AJ8/QORUJCvf1BgcKitgwvDsWPHzp49u2XLlozMzNy8/JzcvJzcvOyc3OKSUhdXdwdrs/LCzPzMpIKs5IKs5MLslNz0hMyUmKy0xJw4X4a+/gkubh7evv6hYREhoREhoeEhIEZ4RFSMnb2TjbVlTFREeGhwRFhIVERYgJ9Pc2P9pvVrFi+cN29aD4Oiijq/kJiAsBiPgDAPvzCvgDAXryAHNx8HNx+vgDCvoCg7Nz8HjwAEsXHxiYhLKyiry8grSSuoMmjpWuoZ2OoZ2uoZ2mvrm2jrGmho66lp6sgrqUrKKErLKsrIKSEjKRkFcSlZCWk5CSk5BjVNXVUNHRUwUtXUVdXUlVfWkFdWl1VUlZZXwUQy8iqyCqoyYMQgp6Qup6QBQsrqcsoQhoackjrYCAKIQUldV0ldB0yiIR0EUsOOGNS0DeFIFQdSQ0dGarrGajpGDBycXDp6ei5ubuKSkmzs7OwcHJiIg4uLi4+Xj19AUFCIi1+Qg0+IX0qeV0QCAFzpAH4OqPEpAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1c938d9392f59a9182a7cb0cf90e77ce/e4706/IP-Cam-Viewer_Android_(4).avif 230w", "/en/static/1c938d9392f59a9182a7cb0cf90e77ce/d1af7/IP-Cam-Viewer_Android_(4).avif 460w", "/en/static/1c938d9392f59a9182a7cb0cf90e77ce/7f308/IP-Cam-Viewer_Android_(4).avif 920w", "/en/static/1c938d9392f59a9182a7cb0cf90e77ce/d7c22/IP-Cam-Viewer_Android_(4).avif 1280w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1c938d9392f59a9182a7cb0cf90e77ce/a0b58/IP-Cam-Viewer_Android_(4).webp 230w", "/en/static/1c938d9392f59a9182a7cb0cf90e77ce/bc10c/IP-Cam-Viewer_Android_(4).webp 460w", "/en/static/1c938d9392f59a9182a7cb0cf90e77ce/966d8/IP-Cam-Viewer_Android_(4).webp 920w", "/en/static/1c938d9392f59a9182a7cb0cf90e77ce/af3f0/IP-Cam-Viewer_Android_(4).webp 1280w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1c938d9392f59a9182a7cb0cf90e77ce/81c8e/IP-Cam-Viewer_Android_(4).png 230w", "/en/static/1c938d9392f59a9182a7cb0cf90e77ce/08a84/IP-Cam-Viewer_Android_(4).png 460w", "/en/static/1c938d9392f59a9182a7cb0cf90e77ce/c0255/IP-Cam-Viewer_Android_(4).png 920w", "/en/static/1c938d9392f59a9182a7cb0cf90e77ce/21b4d/IP-Cam-Viewer_Android_(4).png 1280w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1c938d9392f59a9182a7cb0cf90e77ce/c0255/IP-Cam-Viewer_Android_(4).png",
              "alt": "IP Cam Viewer for your INSTAR IP Camera",
              "title": "IP Cam Viewer for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Fig4.`}</strong>{` `}<em parentName="p">{`VGA Camera`}</em>{`: Choose the correct INSTAR camera profile, type in your camera´s IP or `}<a parentName="p" {...{
        "href": "/en/Internet_Access/The_DDNS_Service/"
      }}>{`DDNS Address`}</a>{` and web port and add your personal camera login.`}</p>
    <p>{`Please provide the following data to add your camera:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Camera Name`}</strong>{`: You can choose a name for your camera - the name does not have to be identical with the camera name inside the web user interface.`}</li>
      <li parentName="ul"><strong parentName="li">{`Camera Model`}</strong>{`: IP Cam Viewer allows you to add cameras from different manufacturers beside INSTAR. Please choose the correct brand and model of your camera.`}</li>
      <li parentName="ul"><strong parentName="li">{`Hostname/IP Address`}</strong>{`: Type your camera's local IP address (e.g. 192.168.178.26) to access your camera at home or use your DDNS ADDRESS (e.g. abcd12.ddns-instar.de) when you want to be able to access your camera from the road. Please be aware that you will need a PORTFORWARDING RULE inside your internet router to use the DDNS service! You do not need to add the camera's port here if your camera uses a http port different than the default 80.`}</li>
      <li parentName="ul"><strong parentName="li">{`Port`}</strong>{`: Type in the camera's http port, that can be found and set in the camera's web user interface under Network/IP Configuration. The default value is 80.`}</li>
      <li parentName="ul"><strong parentName="li">{`Username`}</strong>{`: To access your camera you will need to provide the username you use to log in to your camera's web user interface. The default username for both HD and VGA cameras is admin.`}</li>
      <li parentName="ul"><strong parentName="li">{`Password`}</strong>{`: Provide the password you use to log in to your camera's web user interface. The default password is instar for HD cameras and no password for VGA cameras.`}</li>
    </ul>
    <h3 {...{
      "id": "advanced-settings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#advanced-settings",
        "aria-label": "advanced settings permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Advanced Settings`}</h3>
    <p>{`Use the More Options button in the Add Camera wizard to activated more functions in the Advanced Settings menu.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2c7b17de910139966c97b54017b09e38/21b4d/IP-Cam-Viewer_Android_(5).png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.60869565217391%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsTAAALEwEAmpwYAAABRUlEQVQoz5XLW0vCYACA4U8v0uncdGuuubmD7uA29+2kUqhhJUJQOtE7/8KgCCPpIqJitNiPjkE3gch8eW5fEIZhkiRRFMVp3/G/fvYWx3GSJLvdDvi93mazWa3Wd/fzxSJIBakgWKaWeyyCYLVej8ZjQJAUWsGRUvmkUAS5PMheLg9Eqd1qy01BJEgKrxLZYXgNCJIstVWKZlCsitfIo/zNHC81OIGiG8QpnR2Ajg+9vuMPTMtROqasGdkBE7omdA3osrzEcMJRAM2KZ5zICjLF8HWGZ5qt7MDw8vpidGXa/mgyPR9OdMs1oJeND97eP7cvr+5g+PEVPTw9K7ptwJ5u+YdppqvoNlC7ntr1dNiXDUfSLFm3D1MMl1fMm9t5+LgFpTIKHWc6mzEsW0SQQnEPpIJieLVGkCRJlXCigNXqLU1UO7+0yoG5r5JCeQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2c7b17de910139966c97b54017b09e38/e4706/IP-Cam-Viewer_Android_(5).avif 230w", "/en/static/2c7b17de910139966c97b54017b09e38/d1af7/IP-Cam-Viewer_Android_(5).avif 460w", "/en/static/2c7b17de910139966c97b54017b09e38/7f308/IP-Cam-Viewer_Android_(5).avif 920w", "/en/static/2c7b17de910139966c97b54017b09e38/d7c22/IP-Cam-Viewer_Android_(5).avif 1280w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2c7b17de910139966c97b54017b09e38/a0b58/IP-Cam-Viewer_Android_(5).webp 230w", "/en/static/2c7b17de910139966c97b54017b09e38/bc10c/IP-Cam-Viewer_Android_(5).webp 460w", "/en/static/2c7b17de910139966c97b54017b09e38/966d8/IP-Cam-Viewer_Android_(5).webp 920w", "/en/static/2c7b17de910139966c97b54017b09e38/af3f0/IP-Cam-Viewer_Android_(5).webp 1280w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2c7b17de910139966c97b54017b09e38/81c8e/IP-Cam-Viewer_Android_(5).png 230w", "/en/static/2c7b17de910139966c97b54017b09e38/08a84/IP-Cam-Viewer_Android_(5).png 460w", "/en/static/2c7b17de910139966c97b54017b09e38/c0255/IP-Cam-Viewer_Android_(5).png 920w", "/en/static/2c7b17de910139966c97b54017b09e38/21b4d/IP-Cam-Viewer_Android_(5).png 1280w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2c7b17de910139966c97b54017b09e38/c0255/IP-Cam-Viewer_Android_(5).png",
              "alt": "IP Cam Viewer for your INSTAR IP Camera",
              "title": "IP Cam Viewer for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Fig5.`}</strong>{` Activate advanced features.`}</p>
    <h3 {...{
      "id": "the-live-view-window",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#the-live-view-window",
        "aria-label": "the live view window permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The Live View Window`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/13451c6050459ea4baa37b95ae7cb32d/21b4d/IP-Cam-Viewer_Android_(14).png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.60869565217391%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsTAAALEwEAmpwYAAADJElEQVQozwEZA+b8AJ67p8rez8LW6rTM3e3x9vz4+PP19/f7/fX3/IemoHijjaS8yJWtxqS70aC3z6K50KO50LLE1bPG1nmPowDi6/n4+v3F1+fA0+bd5vD////+/v3h5N9daVN7iojS2+/M1+Git8qdtMqiuM2kuc6twNO2x9esvtB1ip0Avc7Z8fj+zdjfna2jeIdznKWSaHdbMUcbCioAip2P8vj/4OfswtDdnrTHmK7BqLvNv83bvczbma7DdoqbAPX6/8DKxlpwUi5LEjBOFDpcGzlbGzVYGxlBBnmXidnk8+Ll5srW4aC2yaW6y52xxMTS4L/O26y/zoyfrACmraIpPSAfPQ80VCkyTyU7ZCU1YCE7ayUvaxaGo4Xn7fuEiHekqJ+6xs24xcqjtsSTo6ilsrfN3OagtL0AKEMRT3UoWn81Z5NDTHYyKFAdL1giNF4lGUcKg6CFrLOynZt+kpBukZR3np+Fk5iBlpd3paucs8nVm7G7AEJmHUdzHjZgGh44FBg8CR1BDzljITJqGRU8BzRCNHuFbqSig4+Tc4WOc4KMeYWLcpudfZaZfombmIWamgBbZUNIUz5MWEJNZENkhFs6SjtBRTI7Siw9PxJSTziWk4CPinJ7eGdub2VxbFyBfmh+emV4emp9cGJ7aFwAlZmSj4+PeHh3v8rGxtvZsLy3R0hAJiwlMykScmxXoZuFopuDrqaMsamNsamMtaqNrKKHnZh+pYx2glJJAJaZk2NlXUJCOpiVjaCim5iUi0VDOiYpHCUqIGVkU6SbgKKZfqWcf6idf6idf6acfaacfqief5mUfW1zagCbmY6al4p+fG8xLyofIR4TFBA0Myt7eGl+emqGgWuXjXGbknWglXiil3qil3qkmXuhlXeckXOWimuGfWQAtrGivLeow76xhYJ5XVpRZl9RnpaHsq2ct6+ek4x1hXxfkIZqlIpul41wmY5wmo5vl4tskodojYNlgnxlAB4dG0REQV9gXTo7OCcmIyMgHCQiHxwbGBkYFhYVEhUUDxYVEBkaFB4dGCIgGyQhHRodGhgeHBwlJBkeHWUijTIiCypkAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/13451c6050459ea4baa37b95ae7cb32d/e4706/IP-Cam-Viewer_Android_(14).avif 230w", "/en/static/13451c6050459ea4baa37b95ae7cb32d/d1af7/IP-Cam-Viewer_Android_(14).avif 460w", "/en/static/13451c6050459ea4baa37b95ae7cb32d/7f308/IP-Cam-Viewer_Android_(14).avif 920w", "/en/static/13451c6050459ea4baa37b95ae7cb32d/d7c22/IP-Cam-Viewer_Android_(14).avif 1280w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/13451c6050459ea4baa37b95ae7cb32d/a0b58/IP-Cam-Viewer_Android_(14).webp 230w", "/en/static/13451c6050459ea4baa37b95ae7cb32d/bc10c/IP-Cam-Viewer_Android_(14).webp 460w", "/en/static/13451c6050459ea4baa37b95ae7cb32d/966d8/IP-Cam-Viewer_Android_(14).webp 920w", "/en/static/13451c6050459ea4baa37b95ae7cb32d/af3f0/IP-Cam-Viewer_Android_(14).webp 1280w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/13451c6050459ea4baa37b95ae7cb32d/81c8e/IP-Cam-Viewer_Android_(14).png 230w", "/en/static/13451c6050459ea4baa37b95ae7cb32d/08a84/IP-Cam-Viewer_Android_(14).png 460w", "/en/static/13451c6050459ea4baa37b95ae7cb32d/c0255/IP-Cam-Viewer_Android_(14).png 920w", "/en/static/13451c6050459ea4baa37b95ae7cb32d/21b4d/IP-Cam-Viewer_Android_(14).png 1280w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/13451c6050459ea4baa37b95ae7cb32d/c0255/IP-Cam-Viewer_Android_(14).png",
              "alt": "IP Cam Viewer for your INSTAR IP Camera",
              "title": "IP Cam Viewer for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Fig6.`}</strong>{` After adding your camera, you can enter the Multiview window. The Multiview window allows you to keep an eye on all your cameras.`}</p>
    <h3 {...{
      "id": "the-camera-view-window",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#the-camera-view-window",
        "aria-label": "the camera view window permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The Camera View Window`}</h3>
    <p>{`You can access the Camera View by clicking on a camera video in the Multiview window. You will have access to different button layouts depending on the available camera functions of your camera model.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/846252060e442c8691c919b58f4088a6/21b4d/IP-Cam-Viewer_Android_(16).png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.60869565217391%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsTAAALEwEAmpwYAAADJElEQVQozwEZA+b8AC1FSSU0Qz9KVUFMV01VXmxsbWJmaUpVXlFaYGdoaGhnZmdnZm5ub2xrbVBSUQcKBwkRBQ8cCxoeGgsNCgDY5fKnwNV5mrmDocCzy+H6/f/w9/3I3/DL3/Lq8fr////////09/WAi3xHWjs/VjEfPRMeORUhOB4eNhQA6vH19/v+wdHfiKG5mrDE2OLq7vX8vsvQlqaadYRwnaaUaXhbL0UbFTQAGTYGLUkcHzUXAg0CBxoFDCUIAIWRkq24vMjS1+30+PT6/+rx9qazqUtjPjBNETJQFTtdHDZZGjJWGSVJFBxGEBAsChMwDw0iCQooBDVdFQA3PjQ6QTu+x8zj6OrX2tV/inkWLg0oRhcxTicxTSM7ZCU5ZCQ3ZyE6ciMvZxQnURIaPw0uVhZHcBdAZBoAPUQ6QUY+4+bmucG3N0krNlQZTnItWH01aJZESXExKFEdKVAfNmAmJVIaLWMTPX0UI3AKGj8PFy0LDBUEACMtGSw3IV1mSk9ZODtXG0hxH0t7IDZcHCRBGRk/Ch5EETxqIzJsGR1HDA8sCSVaCS9nDRs7B0pIGUY7GAA7QCxFTjdUWzlZYEVodUpDUDBATjQ9TDJCXTRRcUcuPiw8QSo4TCxASRktMQ4THBERDgMWFQU/NA4sKggAYlxSc29jnpyJkZGLYWNcqaymenp5lJeWwtPOxt3ZpLGtREQ7MTcyPDAYMy0YERUUHR4aEhEMPiIAaToAAG5xcI6Yi4qJkGdnZnl7d5uemENGQFtaU6mnoKaqpaqnnktJQBwfFCotIhgeGA8TDhgaFQwNCSYWAFw2AwCcnqisqqGnp66pqKmkpKGRkIeKiHpfXlQ7ODMzNC8kJB8vMChnZlhualpralxlZVdWVUdNSz1DQzc0MyYAj4+Qn5qZo6WTkpKKkZGSo6Cswb2srKicUlFKOTcwRUA1g3xuraiXs6uar6mXp6KSnpmIlZKCiol4fH1uABcYFzw+PVZWUiMlIhIRExYWGxwcGSEhHyYlIx8eGx8dGSQiHh4gGyAgHSQjISUkIRocHBceHx0nKRwjIu2pFRj4xktKAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/846252060e442c8691c919b58f4088a6/e4706/IP-Cam-Viewer_Android_(16).avif 230w", "/en/static/846252060e442c8691c919b58f4088a6/d1af7/IP-Cam-Viewer_Android_(16).avif 460w", "/en/static/846252060e442c8691c919b58f4088a6/7f308/IP-Cam-Viewer_Android_(16).avif 920w", "/en/static/846252060e442c8691c919b58f4088a6/d7c22/IP-Cam-Viewer_Android_(16).avif 1280w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/846252060e442c8691c919b58f4088a6/a0b58/IP-Cam-Viewer_Android_(16).webp 230w", "/en/static/846252060e442c8691c919b58f4088a6/bc10c/IP-Cam-Viewer_Android_(16).webp 460w", "/en/static/846252060e442c8691c919b58f4088a6/966d8/IP-Cam-Viewer_Android_(16).webp 920w", "/en/static/846252060e442c8691c919b58f4088a6/af3f0/IP-Cam-Viewer_Android_(16).webp 1280w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/846252060e442c8691c919b58f4088a6/81c8e/IP-Cam-Viewer_Android_(16).png 230w", "/en/static/846252060e442c8691c919b58f4088a6/08a84/IP-Cam-Viewer_Android_(16).png 460w", "/en/static/846252060e442c8691c919b58f4088a6/c0255/IP-Cam-Viewer_Android_(16).png 920w", "/en/static/846252060e442c8691c919b58f4088a6/21b4d/IP-Cam-Viewer_Android_(16).png 1280w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/846252060e442c8691c919b58f4088a6/c0255/IP-Cam-Viewer_Android_(16).png",
              "alt": "IP Cam Viewer for your INSTAR IP Camera",
              "title": "IP Cam Viewer for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Fig7.`}</strong>{` Selecting one camera in the Multiview window leads you to the Camera View which allows you to control your camera.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      